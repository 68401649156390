// If you don't want to use TypeScript you can delete this file!
import { PageProps } from 'gatsby';
import * as React from 'react';

import { Wrapper } from '@components/Wrapper';

interface DataProps {}

const UsingTypescript: React.FC<PageProps<DataProps>> = () => (
	<Wrapper>
		<h1>404: not found</h1>
	</Wrapper>
);

export default UsingTypescript;
